// eslint-disable-next-line @typescript-eslint/naming-convention
import ReactLenis, { type ReactLenisOptions } from '@studio-freight/react-lenis';
import { type PropsWithChildren, type ReactElement } from 'react';

type SmoothScrollerProps = Partial<Pick<ReactLenisOptions, 'duration'>>;

function SmoothScroller({
  duration = 1.2,
  children
}: PropsWithChildren<SmoothScrollerProps>): ReactElement {
  return (
    <ReactLenis root duration={duration}>
      {children}
    </ReactLenis>
  );
}

export default SmoothScroller;
