import { client } from '@ui/utils/client';
import React from 'react';
import { join } from 'path';
import dynamic from 'next/dynamic';
import { pruneEmpty } from '@ui/utils/pruneEmpty';
import getLang from '@ui/legacy/utils/getLang';

import ContentModule from '@ui/legacy/components/ContentModule';
import Layout from '@ui/legacy/components/Layout';
import SubLayoutNewSite from '@ui/legacy/components/Layout/SubLayoutNewSite';
import SubLayoutOldSite from '@ui/legacy/components/Layout/SubLayoutOldSite';
// const SubLayoutNewSite = React.lazy(() => import('@ui/legacy/components/Layout/SubLayoutNewSite'));
// const SubLayoutOldSite = React.lazy(() => import('@ui/legacy/components/Layout/SubLayoutOldSite'));
const AppProvider = dynamic(() =>
  import('@ui/AppProvider/AppProvider').then((mod) => mod.AppProvider)
);
export type PageStaticPropsProps = {
  params: {
    slug?: string[];
  };
  locale: string;
};

export type PageGetStaticPathsProps = {
  locales: string[];
};

const preview = process.env.CONTENTFUL_USE_PREVIEW === 'true';
const site = process.env.SITE;
// const pagesRevalidate = parseInt(process.env.PAGES_REVALIDATE as string, 10) ?? 3600;
// const revalidate = !isNaN(pagesRevalidate) ? pagesRevalidate : false;

const FILTERED_PAGES = [
  'account/profile',
  'account/order-history',
  'account/activity',
  'account/account-details',
  'account/refer-a-friend',
  'auth'
] as const;

// These are the most common top level paths which get their own page in the pages dir
const SPECIFIC_PATHS = [
  'recipes',
  'blog',
  'media',
  'foodservice',
  'products',
  'company',
  'careers'
] as const;

export type ValidFirstLevelSlug = (typeof SPECIFIC_PATHS)[number];

export const getGetStaticPathsFunction = (firstLevelSlug?: ValidFirstLevelSlug) => {
  return async ({ locales }: PageGetStaticPathsProps) => {
    try {
      if (firstLevelSlug && !SPECIFIC_PATHS.includes(firstLevelSlug)) {
        throw new Error(`Invalid first Level Slug: ${firstLevelSlug}`);
      }

      const allPaths = (await client.Paths({ locales, preview, site }))?.data?.paths;
      const paths = firstLevelSlug
        ? allPaths
            ?.filter((p) => p.params.slug?.[0] === firstLevelSlug)
            ?.map((p) => ({
              ...p,
              params: {
                // remove first segment
                slug: p.params.slug?.slice(1)
              }
            })) ?? []
        : allPaths
            ?.filter((p) => !FILTERED_PAGES.includes(p.params.slug?.join('/')! as any))
            ?.filter((p) => !SPECIFIC_PATHS.includes(p.params.slug?.[0] ?? ('' as any))) ?? [];

      return {
        paths,
        fallback: false
      };
    } catch (error) {
      return {
        paths: [],
        fallback: false
      };
    }
  };
};

export const getGetStaticPropsFunction =
  (firstLevelSlug?: ValidFirstLevelSlug) =>
  async ({ params, locale = 'en-US' }: PageStaticPropsProps) => {
    try {
      const lang = getLang(locale);
      const path = join(`/${firstLevelSlug || ''}`, (params.slug ?? ['/']).join('/'));

      let adjustedPath = path;

      if (path === '/404_') {
        let adjustedPath = '/404';
      } else if (path === '/' && locale === 'en-US') {
        adjustedPath = '/mm-homepage';
      }

      const { data: pageData } = await client.Page({
        path: adjustedPath,
        locale: lang,
        preview,
        site
      });

      if (!pageData?.page) {
        return {
          notFound: true,
          // revalidate
          revalidate: false
        };
      }

      // TODO: this would be better done through extensions
      // but not as easy with the separate nature of the graphql query
      const customHeader = (pageData?.page as any)?.customHeader;

      // override global header with custom header
      if (customHeader) {
        pageData.settings = {
          ...pageData.settings,
          globalHeader: customHeader
        } as any;
      }

      const hideGlobalFooter = (pageData?.page as any)?.hideGlobalFooter;

      let prunedPageData = pruneEmpty(pageData);

      // override globalFooter to hide
      if (!!hideGlobalFooter) {
        prunedPageData.settings = {
          ...prunedPageData.settings,
          globalFooter: null
        } as any;
      }

      const overrideFooterProps = (pageData?.page as any)?.overrideFooterProps;

      if (overrideFooterProps) {
        const lastComponentPalette =
          prunedPageData?.page?.contents?.[prunedPageData?.page?.contents.length - 1]?.palette;

        if (lastComponentPalette) overrideFooterProps.shapePalette = lastComponentPalette;

        prunedPageData.settings = {
          ...prunedPageData.settings,
          overrideFooterProps
        } as any;
      }

      return {
        props: {
          pageData: prunedPageData,
          locale
        },
        // revalidate
        revalidate: false
      };
    } catch (err: any) {
      if (err.name == 'FetchError') {
        console.log('[Error][GetStaticProps]', err);
      } else {
        console.log(err);
      }
      throw err;
    }
  };

export const Page = function Page({ pageData, locale, ...other }: any) {
  return (
    <AppProvider
      __namespaces={{ common: pageData?.settings?.localizationLookup }}
      __lang={locale}
      contentGroup={pageData?.page?.contentGroup}
      pageProps={pageData?.page}>
      <Layout
        title={pageData?.page?.title}
        includedLocales={pageData?.page?.includedLocales}
        seo={pageData?.page?.seo}
        settingsGlobal={pageData?.settings}
        slug={pageData?.page.slug}>
        {pageData?.page?.__typename === 'MmPage' ? (
          <SubLayoutNewSite>
            <ContentModule {...pageData?.page} />
          </SubLayoutNewSite>
        ) : (
          <SubLayoutOldSite>
            <ContentModule {...pageData?.page} />
          </SubLayoutOldSite>
        )}
      </Layout>
    </AppProvider>
  );
};
