/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import * as React from 'react';

import PropTypes from 'prop-types';

// import ErrorBoundary from '../ErrorBoundary';
import { TransitionPresence } from '@mediamonks/react-kit';
import SmoothScroller from '../../../new-site/components/utils/smooth-scroller/SmoothScroller';
import ThemeRegistry from '../../../components/core/ThemeRegistry/ThemeRegistry'; // Import the necessary module

export const LayoutPropTypes = {
  children: PropTypes.node.isRequired
};

function SubLayoutNewSite({ children }: { children: React.ReactNode }) {
  return (
    <SmoothScroller key="app" duration={1.6}>
      <TransitionPresence>
        <ThemeRegistry>{children}</ThemeRegistry>
      </TransitionPresence>
    </SmoothScroller>
  );
}

SubLayoutNewSite.propTypes = LayoutPropTypes;

SubLayoutNewSite.defaultProps = {};

export default SubLayoutNewSite;
