/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
// import textStyles from '../../styles/base/text.scss';
// import * as styles from '../LayoutOld.module.scss';

import PropTypes from 'prop-types';

import styled from 'styled-components';

// const LayoutContainer = styled.div`
//   ${textStyles}
// `;

import ErrorBoundary from '../ErrorBoundary';

export const LayoutPropTypes = {
  children: PropTypes.node.isRequired
};

function SubLayoutOldSite({ children }: { children: React.ReactNode }) {
  // React.useEffect(() => {
  //   document.documentElement.classList.remove('bodyMmPage');
  //   document.documentElement.classList.add('bodyNotMmPage');
  // }, []);

  return (
    <ErrorBoundary>
      {/* <LayoutContainer>{children}</LayoutContainer> */}
      {children}
    </ErrorBoundary>
  );
}

SubLayoutOldSite.propTypes = LayoutPropTypes;

SubLayoutOldSite.defaultProps = {};

export default SubLayoutOldSite;
